/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { Carousel } from 'src/components/core/carousel';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { Heading } from 'src/components/core/heading';
import { ReactNode } from 'react';
import { Section } from 'src/components/core/layout/section';

/**
 * `Props` type.
 */

type Props = {
  hasGradient?: boolean;
  label?: string;
  lead?: string;
  slides: ReactNode[];
  title?: string;
};

/*
 * Export `ServiceCarousel` component.
 */

export const ServiceCarousel = (props: Props) => {
  const { hasGradient, slides, ...rest } = props;

  return (
    <AnimationController>
      <Section
        style={{ background: hasGradient ? 'var(--gradient-tertiary--0-deg)' : 'transparent', overflow: 'hidden' }}
      >
        <Grid>
          <SubGrid>
            <Heading asTitle={'h3'} hasMaxWidth offset titleSize={28} {...rest} />
          </SubGrid>
        </Grid>

        <Grid data-has-offset-gutter>
          <SubGrid style={{ minWidth: 0 }}>
            <Carousel pagination={{ clickable: true }} style={{ paddingTop: 'var(--space-xs)' }}>
              {slides}
            </Carousel>
          </SubGrid>
        </Grid>
      </Section>
    </AnimationController>
  );
};
