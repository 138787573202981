/*
 * Modules dependencies
 */

import { Button } from 'src/components/core/buttons/button';
import { Heading } from 'src/components/core/heading';
import { Image } from 'src/components/core/image';
import { Text } from 'src/components/core/text';
import { breakpoints } from '@untile/react-core/styles/breakpoints';
import { media } from '@untile/react-core/styles/media';
import Icon from 'src/assets/svgs/arrow-right.svg';
import styled from 'styled-components';

/*
 * Props types
 */

type Props = {
  animateHeading?: boolean;
  badge?: string;
  className?: string;
  cta?: { label: string; url: string };
  description: string;
  image: { alt?: string; src: string };
  label: string;
  title: string;
};

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  background: var(--gradient-secondary--45-deg);
  border-radius: var(--border-radius-sm);
  display: grid;
  grid-template-areas: 'image' 'content';
  grid-template-rows: 168px auto;

  ${media.min.md`
    grid-template-rows: 192px auto;
  `}
`;

/*
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  border-radius: var(--border-radius-sm);
  grid-area: image;
  overflow: hidden;
  position: relative;
`;

/*
 * `Content` styled component.
 */

const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
  justify-content: space-between;
  padding: var(--space-sm) var(--space-sm) var(--space-xs);

  ${media.min.md`
    padding: var(--space-ms) var(--space-sm) var(--space-sm);
  `}

  ${media.min.xl`
    padding: var(--space-sm) var(--space-sm) var(--space-xs);
  `}
`;

/**
 * `Badge` styled component.
 */

const Badge = styled.div`
  align-items: center;
  background: var(--gradient-primary--15-deg);
  border-radius: var(--border-radius-sm);
  bottom: 8px;
  color: var(--color-slate900);
  padding: calc(var(--space-xxs) / 2) var(--space-xxs);
  position: absolute;
  right: 8px;
`;

/**
 * Export `CardExtra` component.
 */

export function CardExtra(props: Props) {
  const { animateHeading, badge, className, cta, description, image, label, title } = props;

  return (
    <Grid className={className}>
      <ImageWrapper>
        <Image
          alt={image?.alt ?? title}
          fill
          sizes={`(max-width: ${breakpoints.md}px) 100vw, (max-width: ${breakpoints.lg}px) 33vw, 33vw`}
          src={image?.src}
          style={{ objectFit: 'cover' }}
        />

        {badge && (
          <Badge>
            <Text as={'p'} variant={'labelM'} weight={600}>
              {badge}
            </Text>
          </Badge>
        )}
      </ImageWrapper>

      <Content>
        <Heading
          description={description}
          descriptionSize={16}
          label={label}
          shouldAnimate={animateHeading}
          titleSize={28}
        />

        {cta && (
          <Button
            aria-label={cta.label}
            colorTheme={'text'}
            href={cta.url}
            icon={Icon}
            reverse
            style={{ alignSelf: 'start' }}
          >
            {cta.label}
          </Button>
        )}
      </Content>
    </Grid>
  );
}
