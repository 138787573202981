/*
 * Modules dependencies
 */

import styled from 'styled-components';

/**
 * Export `CardGradient` styled component.
 */

export const CardGradient = styled.div`
  background: var(--gradient-secondary--45-deg--transparent);
  border-radius: var(--border-radius-sm);
`;
