/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { Button } from 'src/components/core/buttons/button';
import { CardTestimonial } from 'src/components/core/cards/card-testimontial';
import { Carousel, Slide } from 'src/components/core/carousel';
import { Content } from '@prismicio/client';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { Heading } from 'src/components/core/heading';
import { ReactNode } from 'react';
import { Section } from 'src/components/core/layout/section';
import { media } from '@untile/react-core/styles/media';
import { useTranslation } from 'next-i18next';
import chatIcon from 'src/assets/svgs/chat.svg';
import linkIcon from 'src/assets/svgs/link.svg';
import styled from 'styled-components';

/**
 * `FeedbackProps` type.
 */

type FeedbackProps = {
  active: boolean;
  customer_name: string;
  rating: Content.HomeDocumentDataCustomerFeedbackItem['rating'];
  review: ReactNode;
};

/**
 * `Props` type.
 */

type Props = {
  feedback: FeedbackProps[];
};

/**
 * `StyledGrid` styled component.
 */

const StyledGrid = styled(Grid)`
  ${media.min.md`
    grid-template-columns: 0.75fr 10fr 0.75fr;
  `}
`;

/**
 * `StyledSubGrid` styled component.
 */

const StyledSubGrid = styled(SubGrid)`
  ${media.max.md`
    padding-inline: var(--space-sm);
  `}
`;

/**
 * `StyledCarousel` styled component.
 */

const StyledCarousel = styled(Carousel)`
  --swiper-pagination-bullet-inactive-color: var(--color-cyan200);
  --swiper-pagination-color: var(--color-slate900);
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button).attrs({
  colorTheme: 'minimal',
  icon: linkIcon,
  reverse: true
})`
  --button-text-color: var(--color-slate400);
  --button-focused-text-color: var(--color-cyan400);
`;

/**
 * Export `Testimonials` component.
 */

export const Testimonials = ({ feedback }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Section
      style={{
        backgroundColor: 'var(--color-cyan50)',
        overflow: 'hidden',
        padding: 'var(--section-space) var(--gutter)'
      }}
    >
      <Grid>
        <StyledSubGrid>
          <AnimationController>
            <Heading
              asTitle={'h2'}
              hasMaxWidth
              icon={chatIcon}
              label={t('testemonialSection.label')}
              title={t('testemonialSection.title')}
              titleTheme={'slate900'}
            />

            <FadeInUp>
              <StyledButton
                aria-label={t('testemonialSection.button.label')}
                href={t('testemonialSection.button.href')}
              >
                {t('testemonialSection.button.label')}
              </StyledButton>
            </FadeInUp>
          </AnimationController>
        </StyledSubGrid>
      </Grid>

      <StyledGrid>
        <SubGrid style={{ minWidth: 0, paddingTop: 'var(--space-ms)' }}>
          <StyledCarousel pagination={{ clickable: true }}>
            {feedback.map(
              (item, index) =>
                item.active && (
                  <Slide key={index}>
                    <FadeInUp delay={index * 0.2} style={{ height: '100%' }} triggerInView>
                      <CardTestimonial customerName={item.customer_name} rating={item.rating} review={item.review} />
                    </FadeInUp>
                  </Slide>
                )
            )}
          </StyledCarousel>
        </SubGrid>
      </StyledGrid>
    </Section>
  );
};
