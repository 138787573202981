/**
 * Module dependencies.
 */

import { Benefit, CardServiceBenefits } from 'src/components/core/cards/card-service-benefits';
import { Button } from 'src/components/core/buttons/button';
import { Content } from '@prismicio/client';
import { ExtrasSection } from 'src/components/sections/extras';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { GetStaticProps, NextPage } from 'next';
import { Heading } from 'src/components/core/heading';
import { Hero } from 'src/components/sections/hero';
import { MovelUnit } from 'src/components/home/movel-unit';
import { SEO } from 'src/components/core/seo';
import { SSGProps } from 'src/types/app';
import { Scan } from 'src/components/home/scan';
import { ServiceCarousel } from 'src/components/sections/services/carousel';
import { Slide } from 'src/components/core/carousel';
import { Testimonials } from 'src/components/home/testimonials';
import { createPrimsicClient } from 'src/prismic/client';
import { getDataFromTranslations } from 'src/core/utils/objects';
import { media } from '@untile/react-core/styles/media';
import { normalizePrismicSEO } from 'src/prismic/utils';
import { routeResolve } from 'src/core/utils/routes';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useScroll, useSpring, useTransform } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { withSettings } from 'src/hocs/with-settings';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  data: Content.HomeDocumentData;
};

/**
 * `StyledHeading` styled component.
 */

const StyledHeading = styled(Heading)`
  position: relative;
  z-index: 1;

  ${media.max.xs`
    margin-bottom: 0;
  `}
`;

/**
 * `Home` page.
 */

const Home: NextPage<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { data: benefitsList } = getDataFromTranslations<Benefit>('home:benefitsSection.list', t);
  const { scrollYProgress } = useScroll();
  const isMobile = useBreakpoint(0, 'sm');
  const isSmallMobile = useBreakpoint(0, 'xs');
  const scrollSpring = useSpring(scrollYProgress, { damping: 50, stiffness: 400 });
  const heroImgParallaxY = useTransform(scrollSpring, [0, 1], ['2%', '900%']);

  return (
    <>
      <SEO {...normalizePrismicSEO(data)} />

      <Hero
        image={{
          alt: t('home:hero.image.alt'),
          motionStyle: { x: '35%', y: heroImgParallaxY },
          slideIn: { x: '0%' },
          src: isMobile ? t('home:hero.image.mobileSrc') : t('home:hero.image.src'),
          style: {
            backgroundPosition: isMobile ? '0% 100%' : '25% 20%',
            backgroundSize: isMobile ? 'cover' : 'contain',
            transform: 'translate(35%, 15%)'
          }
        }}
        overflowVisible
      >
        <StyledHeading
          asDescription={'p'}
          asTitle={'h1'}
          description={t('home:hero.description')}
          hasMaxWidth
          staggerMobile
          title={t('home:hero.title')}
          titleTheme={'slate50'}
        />

        {!isSmallMobile && (
          <FadeInUp staggerMobile style={{ paddingTop: 'var(--space-sm)' }}>
            <Button aria-label={t('home:hero.buttonLabel')} href={routeResolve('schedule')} size={'medium'}>
              {t('home:hero.buttonLabel')}
            </Button>
          </FadeInUp>
        )}
      </Hero>

      <ServiceCarousel
        slides={benefitsList.map(({ ...rest }, index) => (
          <Slide key={index}>
            <FadeInUp style={{ height: '100%' }}>
              <CardServiceBenefits {...rest} />
            </FadeInUp>
          </Slide>
        ))}
        title={t('home:benefitsSection.title')}
      />

      <Scan />

      <ExtrasSection />

      <MovelUnit />

      <Testimonials feedback={data?.customer_feedback as []} />
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = withSettings<any>(async ({ locale, previewData }) => {
  const client = createPrimsicClient({ previewData });
  const { data } = await client.getSingle('home');

  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common', 'home'])),
      data,
      hideNavbarBackground: true
    },
    revalidate: 60
  };
});

/**
 * Export `Home` page.
 */

export default Home;
