/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { MotionStyle, motion, useScroll, useTransform } from 'framer-motion';
import { Section } from 'src/components/core/layout/section';
import { media } from '@untile/react-core/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useSettings } from 'src/providers/settings';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  className?: string;
  image?: {
    alt: string;
    motionStyle?: MotionStyle;
    slideIn?: {
      x?: string;
    };
    src: string;
    style?: CSSProperties;
  };
  overflowVisible?: boolean;
};

/**
 * `StyledSection` styled component.
 */

const StyledSection = styled(Section)`
  --hero-section-padding-top: 72px;

  padding: 0 var(--gutter) !important;
  position: sticky;
  z-index: var(--z-index-hero);
`;

/**
 * `StyledGrid` styled component.
 */

const StyledGrid = styled(Grid)`
  background: var(--gradient-secondary--45-deg);
  border-radius: 0 0 var(--space-sm) var(--space-sm);
  display: grid;
  grid-template-areas: 'subgrid' 'image';
  grid-template-rows: 1fr;
  mask-image: radial-gradient(white, black);
  overflow: hidden;

  ${media.min.sm`
    border-radius: 0 0 42px 42px;
  `}

  ${media.min.md`
    grid-template-areas: '. subgrid .' 'image image image';
    grid-template-columns: 1fr 10fr 1fr;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled(SubGrid)`
  grid-row: 1 / -1;
  overflow: hidden;
  padding-bottom: 72px;
  padding-top: calc(var(--navbar-height) + var(--hero-section-padding-top));

  ${media.max.md`
    padding-bottom: 56px;
    padding-left: var(--space-sm);
    padding-top: calc(var(--navbar-height) + 56px);
    padding-right: var(--space-sm);
  `}

  &[data-has-marquee='true'] {
    padding-top: calc(var(--navbar-height) + var(--top-message-height) + var(--hero-section-padding-top));

    ${media.max.md`
      padding-top: calc(var(--navbar-height) + var(--top-message-height) + 56px);
    `}
  }

  &[data-overflow-visible='true'] {
    overflow: visible;
  }
`;

/**
 * `BgImage` styled component.
 */

const BgImage = styled(motion.div)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: image;
  grid-row: 1 / -1;
  overflow: hidden;
  position: relative;
`;

/**
 * Export `Hero` component.
 */

export const Hero = (props: Props) => {
  const { children, className, image, overflowVisible } = props;
  const settings = useSettings();
  const hasMarquee = useMemo(() => settings?.promotional_header_message?.length > 0, [settings]);
  const isMobile = useBreakpoint(0, 'sm');
  const { scrollYProgress } = useScroll();
  const contentParallaxY = useTransform(scrollYProgress, [0, 1], ['0%', '-600%']);
  const [opacity, setOpacity] = useState(1);
  const elementRef = useRef<HTMLDivElement>(null);
  const [topSticky, setTopSticky] = useState<string | number>(0);

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) {
        const height = elementRef.current.clientHeight;

        if (hasMarquee) {
          setTopSticky(`calc(var(--navbar-height) + var(--top-message-height) - ${height}px)`);

          return;
        }

        setTopSticky(`calc(var(--navbar-height) - ${height}px)`);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [hasMarquee]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const newOpacity = entry.intersectionRatio < 0.5 ? 0 : entry.intersectionRatio;

          setOpacity(newOpacity);
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(elementRef?.current);
      }
    };
  }, [elementRef]);

  return (
    <StyledSection className={className} ref={elementRef} style={{ top: topSticky }}>
      <StyledGrid>
        {image?.src && (
          <BgImage
            style={{
              backgroundImage: `url(${image?.src})`,
              opacity,
              transition: 'opacity 150ms linear',
              ...image?.motionStyle,
              ...image?.style
            }}
            title={image?.alt}
            transition={{ duration: 0.5, ease: 'easeOut' }}
            {...(image.slideIn && {
              animate: {
                x: isMobile ? image.slideIn.x || '10%' : '40%'
              }
            })}
            {...(image.slideIn && { initial: { x: '60%' } })}
          />
        )}

        <Content data-has-marquee={hasMarquee} data-overflow-visible={overflowVisible}>
          <motion.div style={{ opacity, transition: 'opacity 150ms linear', y: contentParallaxY }}>
            <AnimationController delayChildren={0.2} staggerMobile>
              {children}
            </AnimationController>
          </motion.div>
        </Content>
      </StyledGrid>
    </StyledSection>
  );
};
