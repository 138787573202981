/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { Button } from 'src/components/core/buttons/button';
import { CallMeModal } from 'src/components/call-me-modal';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { Heading } from 'src/components/core/heading';
import { Image } from 'src/components/core/image';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { breakpoints } from '@untile/react-core/styles/breakpoints';
import { media } from '@untile/react-core/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import movelUnitIcon from 'src/assets/svgs/movel-unit.svg';
import styled from 'styled-components';

/**
 * `StyledSubGrid` styled component.
 */

const StyledSubGrid = styled(SubGrid)`
  display: grid;
  gap: var(--space-ms);
  grid-template-columns: 1fr;

  ${media.max.ms`
    grid-template-rows: min-content 1fr;
    padding-inline: var(--space-sm);
  `}

  ${media.max.md`
    padding-inline: var(--space-sm);
  `}

  ${media.min.ms`
    grid-template-columns: 1.5fr 1fr;
  `}
`;

/*
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled(FadeInUp)`
  align-items: flex-end;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

/**
 * `Badge` styled component.
 */

const Badge = styled.div`
  align-items: center;
  background: var(--gradient-primary--270-deg);
  border-bottom-left-radius: var(--border-radius-sm);
  border-top-left-radius: var(--border-radius-sm);
  bottom: 48px;
  color: var(--color-slate900);
  max-width: 166px;
  padding: var(--space-xs) var(--space-ms) var(--space-xs) var(--space-sm);
  position: absolute;
  right: 0;
`;

/**
 * Export `MovelUnit` component.
 */

export const MovelUnit = () => {
  const { t } = useTranslation('home');
  const isMobile = useBreakpoint(0, 'md');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <AnimationController delayChildren={1}>
      <Section
        style={{
          overflow: 'hidden',
          padding: isMobile ? 'var(--section-space-xl) var(--gutter) 96px' : '80px var(--gutter)'
        }}
      >
        <Grid>
          <StyledSubGrid>
            <div>
              <Heading
                asDescription={'p'}
                asTitle={'h2'}
                description={t('movelUnitSection.description')}
                descriptionTheme={'slate300'}
                icon={movelUnitIcon}
                label={t('movelUnitSection.label')}
                title={t('movelUnitSection.title')}
                titleTheme={'slate50'}
              />

              <FadeInUp style={{ paddingTop: 'var(--space-xs)' }}>
                <Button
                  aria-label={t('common:actions.callMe')}
                  colorTheme={'outlineDarkened'}
                  onClick={() => setIsModalOpen(true)}
                  size={'medium'}
                >
                  {t('common:actions.callMe')}
                </Button>

                <CallMeModal isOpen={isModalOpen} onRequesClose={() => setIsModalOpen(false)} />
              </FadeInUp>
            </div>

            <ImageWrapper>
              <div style={{ height: '100%', position: 'relative', width: '100%' }}>
                <Image
                  alt={t('movelUnitSection.image.alt')}
                  fill
                  sizes={`(max-width: ${breakpoints.xl}px) 75vw, 30vw`}
                  src={t('movelUnitSection.image.src')}
                  style={{ objectFit: 'cover' }}
                />

                <Badge>
                  <Text as={'p'} variant={'bodyXl'} weight={600}>
                    {t('movelUnitSection.badge')}
                  </Text>
                </Badge>
              </div>
            </ImageWrapper>
          </StyledSubGrid>
        </Grid>
      </Section>
    </AnimationController>
  );
};
