/*
 * Modules dependencies
 */

import { Content, RichTextField } from '@prismicio/client';
import { PrismicRichText } from 'src/components/prismic';
import { ReactNode } from 'react';
import { Text } from 'src/components/core/text';
import styled from 'styled-components';

/**
 * Constants.
 */

const outlinePath =
  'M9.69894 3.5187c.86236-2.02998 3.73956-2.02998 4.60196 0l1.5576 3.66643 3.8483.32232c2.1775.18237 3.0896 2.87395 1.4715 4.34245l-2.9429 2.6711.9633 4.4361c.4867 2.2413-2.0493 3.9003-3.9079 2.5564l-3.2909-2.3795-3.29091 2.3795c-1.85858 1.3439-4.39459-.3151-3.90789-2.5564l.9633-4.4361-2.94297-2.671c-1.61809-1.4686-.70601-4.16018 1.4715-4.34255l3.8484-.32232L9.69894 3.5187Zm2.76116.78201c-.1725-.40599-.7479-.40599-.9204 0L9.86418 8.24473c-.21758.51218-.70084.86181-1.25538.90825l-4.14895.34749c-.4355.03648-.61791.57483-.2943.86853l3.15685 2.8651c.397.3603.57151.9051.45774 1.429l-1.02459 4.7184c-.09734.4483.40986.7801.78157.5113l3.58388-2.5913c.5246-.3793 1.2333-.3793 1.7578 0l3.5839 2.5913c.3717.2688.8789-.063.7816-.5113l-1.0246-4.7184c-.1138-.5239.0607-1.0687.4577-1.429l3.1568-2.8651c.3236-.2937.1412-.83205-.2943-.86853l-4.1489-.34749c-.5545-.04644-1.0378-.39607-1.2554-.90825l-1.6755-3.94402Z';

const solidPath =
  'M10.6087 2.9382c.505-1.25175 2.2772-1.25175 2.7821 0l1.9373 4.80212c.0708.17543.2345.2959.423.31128l4.8662.39706c1.3194.10766 1.8627 1.74704.8686 2.62134l-3.6925 3.2477c-.1366.1202-.1972.3051-.1581.4829l1.1808 5.3671c.295 1.3406-1.2167 2.3404-2.335 1.5442l-4.1913-2.9843c-.1736-.1236-.4065-.1236-.58 0l-4.19138 2.9843c-1.11826.7962-2.62995-.2036-2.33499-1.5443l1.18078-5.367c.03911-.1778-.02146-.3627-.15811-.4829L2.51346 11.07c-.99406-.8742-.4508-2.51368.86863-2.62134L8.2484 8.0516c.18854-.01538.35226-.13585.42303-.31128L10.6087 2.9382Z';

/**
 * `Props` type.
 */

type Props = {
  customerName: string;
  rating: Content.HomeDocumentDataCustomerFeedbackItem['rating'];
  review: ReactNode;
};

/*
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: var(--color-cyan100);
  border-radius: var(--space-sm);
  color: var(--color-slate900);
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  justify-content: space-between;
  padding: 40px var(--space-sm) var(--space-sm);
`;

/**
 * `getPath` function.
 */

function getPath(rating: number, index: number): string {
  return rating < index ? outlinePath : solidPath;
}

/**
 * `RatingSvg` component
 */

const RatingSvg = ({ rating: ratingFromProps }: { rating: Content.HomeDocumentDataCustomerFeedbackItem['rating'] }) => {
  const rating = parseInt(ratingFromProps, 10);

  if (ratingFromProps === 'None' || isNaN(rating)) {
    return;
  }

  return (
    <svg fill={'none'} height={'12px'} viewBox={'0 0 120 24'} xmlns={'http://www.w3.org/2000/svg'}>
      <path d={getPath(rating, 1)} fill={'url(#a)'} />
      <path d={getPath(rating, 2)} fill={'url(#a)'} transform={'translate(24)'} />
      <path d={getPath(rating, 3)} fill={'url(#a)'} transform={'translate(48)'} />
      <path d={getPath(rating, 4)} fill={'url(#a)'} transform={'translate(72)'} />
      <path d={getPath(rating, 5)} fill={'url(#a)'} transform={'translate(96)'} />

      <defs>
        <linearGradient
          gradientUnits={'userSpaceOnUse'}
          id={'a'}
          x1={'11.9999'}
          x2={'11.9999'}
          y1={'21.9962'}
          y2={'1.99622'}
        >
          <stop stop-color={'#00a7dc'} />
          <stop offset={'.485'} stop-color={'#00cddc'} />
          <stop offset={'1'} stop-color={'#01dccf'} />
        </linearGradient>
      </defs>
    </svg>
  );
};

/**
 * Export `CardTestimonial` component.
 */

export const CardTestimonial = ({ customerName, rating, review }: Props) => (
  <Wrapper>
    <div style={{ display: 'grid', gap: '14px' }}>
      <Text as={'div'} variant={'bodyL'} weight={400}>
        <PrismicRichText field={review as RichTextField} />
      </Text>

      <RatingSvg rating={rating} />
    </div>

    <Text as={'h2'} variant={'bodyM'} weight={600}>
      {customerName}
    </Text>
  </Wrapper>
);
