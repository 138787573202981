/**
 * Module dependencies
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { CardExtra } from 'src/components/core/cards/card-extra';
import { Carousel, Slide } from 'src/components/core/carousel';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { Heading } from 'src/components/core/heading';
import { RawHtml } from '@untile/react-core/components/raw-html';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useTranslation } from 'next-i18next';
import benefitsSvg from 'src/assets/svgs/benefits.svg';
import styled from 'styled-components';

/**
 * `cards` const.
 */

const cards = ['garanties', 'convenience'] as const;

/**
 * `CardProps` type.
 */

type CardProps = (typeof cards)[number];

/**
 * `StyledHeading` styled component.
 */

const StyledHeading = styled(Heading)`
  margin-bottom: var(--space-ms);

  ${media.max.md`
    padding-inline: var(--space-sm);
  `}
`;

/**
 * `StyledText` styled component.
 */

const StyledText = styled(Text).attrs({ as: 'p', variant: 'bodyXl' })`
  a {
    color: var(--color-cyan400);
    text-decoration: underline;
  }
`;

/**
 * `StyledGrid` styled component.
 */

const StyledGrid = styled(Grid)`
  ${media.min.md`
    grid-template-columns: 0.75fr 10fr 0.75fr;
  `}
`;

/**
 * `StyledCardExtra` styled component.
 */

const StyledCardExtra = styled(CardExtra)`
  height: 100%;
`;

/**
 * `Card` component.
 */

const Card = ({ cardType }: { cardType: CardProps }) => {
  const { t } = useTranslation();

  return (
    <StyledCardExtra
      animateHeading={false}
      badge={t(`common:extras.${cardType}.badge`)}
      cta={{
        label: t(`common:extras.${cardType}.cta.label`),
        url: routeResolve(t(`common:extras.${cardType}.cta.url`))
      }}
      description={t(`common:extras.${cardType}.description`)}
      image={{
        alt: t(`common:extras.${cardType}.image.alt`),
        src: t(`common:extras.${cardType}.image.src`)
      }}
      label={t(`common:extras.${cardType}.label`)}
      title={t(`common:extras.${cardType}.title`)}
    />
  );
};

/**
 * Export `ExtrasSection` component.
 */

export const ExtrasSection = ({
  alternative,
  translationFile = 'common'
}: {
  alternative?: boolean;
  translationFile?: string;
}) => {
  const isMobile = useBreakpoint(0, 'md');
  const { t } = useTranslation();

  return (
    <Section
      style={{
        background: 'var(--gradient-tertiary--0-deg)',
        overflow: 'hidden',
        padding: isMobile ? 'var(--section-space) var(--gutter)' : '80px var(--gutter)'
      }}
    >
      <Grid>
        <SubGrid>
          <AnimationController>
            <StyledHeading
              asDescription={StyledText}
              asTitle={'h2'}
              description={
                <RawHtml>
                  {t(`${translationFile}:extras.description`, {
                    convenineceLink: routeResolve('serviceConveniencia'),
                    garantiesLink: routeResolve('serviceGarantias')
                  })}
                </RawHtml>
              }
              descriptionSize={18}
              descriptionTheme={alternative ? 'slate50' : 'slate100'}
              hasMaxWidth
              icon={benefitsSvg}
              label={t(`${translationFile}:extras.label`)}
              title={t(`${translationFile}:extras.title`)}
              titleSize={36}
              titleTheme={'slate50'}
            />
          </AnimationController>
        </SubGrid>
      </Grid>

      {!alternative && (
        <StyledGrid>
          <SubGrid style={{ minWidth: 0 }}>
            <Carousel pagination={{ clickable: true }}>
              {cards.map((extra, index) => (
                <Slide key={t(`common:extras.${extra}.label`)}>
                  <FadeInUp delay={index * 0.2} style={{ height: '100%' }} triggerInView>
                    <Card cardType={extra} key={index} />
                  </FadeInUp>
                </Slide>
              ))}
            </Carousel>
          </SubGrid>
        </StyledGrid>
      )}
    </Section>
  );
};
