/*
 * Modules dependencies
 */

import { Button } from 'src/components/core/buttons/button';
import { CardGradient } from './card-gradient';
import { Heading } from 'src/components/core/heading';
import { ReactNode } from 'react';
import iconRight from 'src/assets/svgs/arrow-right.svg';
import styled from 'styled-components';

/**
 * Export `Benefit` type.
 */

export type Benefit = {
  cta?: {
    label: string;
    url: string;
  };
  description: string;
  title: string;
};

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  cta?: {
    label: string;
    url: string;
  };
  description: string | ReactNode;
  isDescriptionRaw?: boolean;
  title: string;
};

/**
 * `StyledCardGradient` styled component.
 */

const StyledCardGradient = styled(CardGradient)`
  display: grid;
  height: 100%;
  padding: var(--space-sm);

  ul li {
    list-style: disc;
    margin-left: var(--space-xs);
  }
`;

/**
 * Export `CardServiceBenefits` component.
 */

export function CardServiceBenefits({ isDescriptionRaw, ...rest }: Props) {
  const { cta } = rest;

  return (
    <StyledCardGradient>
      <Heading
        {...rest}
        asTitle={'h4'}
        descriptionSize={16}
        isDescriptionRaw={isDescriptionRaw}
        shouldAnimate={false}
        style={{ marginBottom: 'var(--space-sm)' }}
        titleSize={22}
        titleTheme={'gradient-primary'}
      />

      {cta && (
        <div style={{ alignItems: 'flex-end', display: 'flex' }}>
          <Button aria-label={cta.label} colorTheme={'ghost'} href={cta.url} icon={iconRight} reverse size={'medium'}>
            {cta.label}
          </Button>
        </div>
      )}
    </StyledCardGradient>
  );
}
