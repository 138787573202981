/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { BadgePrice } from 'src/components/core/badges/badge-price';
import { Button } from 'src/components/core/buttons/button';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { Heading } from 'src/components/core/heading';
import { IconParallax } from 'src/components/core/animations/icon-parallax';
import { Image } from 'src/components/core/image';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import { motion, useInView } from 'framer-motion';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import carIcon from 'src/assets/svgs/car.svg';
import electricIcon from 'src/assets/svgs/electric.svg';
import engineIcon from 'src/assets/svgs/engine.svg';
import scan360Icon from 'src/assets/svgs/scan360.svg';
import styled from 'styled-components';

/**
 * `StyledSubGrid` styled component.
 */

const StyledSubGrid = styled(SubGrid)`
  display: grid;
  gap: var(--space-ms);
  grid-template-columns: 1fr;

  ${media.max.ms`
    grid-template-rows: min-content minMax(320px, 1fr);
    padding-inline: var(--space-sm);
  `}

  ${media.max.md`
    padding-inline: var(--space-sm);
  `}

  ${media.min.ms`
    grid-template-columns: 1.5fr 1fr;
  `}
`;

/*
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled(FadeInUp)`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  position: relative;
`;

/**
 * `StyledBadgePrice` styled component.
 */

const StyledBadgePrice = styled(BadgePrice)`
  bottom: 32px;
  position: absolute;
  right: -22px;
`;

/**
 * `IconsGrid` styled component.
 */

const IconsGrid = styled(motion.div)`
  bottom: 0;
  display: grid;
  grid-column-gap: var(--space-xxs);
  grid-template-areas: '. . icon3' '. icon2 .' ' . . icon1';
  grid-template-columns: repeat(3, 1fr);
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  ${media.min.md`
    grid-template-areas: '. . . icon1 . icon2 icon3 .';
    grid-template-columns: repeat(8, 1fr);
    padding-bottom: var(--section-space);
    padding-top: calc(var(--section-space) * 2);
  `}
`;

/**
 * Export `Scan` component.
 */

export const Scan = () => {
  const { t } = useTranslation('home');
  const isMobile = useBreakpoint(0, 'md');

  const iconsGridRef = useRef(null);
  const isInView = useInView(iconsGridRef, { once: false });

  return (
    <AnimationController delayChildren={1}>
      <Section
        style={{
          overflow: 'hidden',
          padding: isMobile ? 'var(--section-space-xl) var(--gutter) 0' : '0 var(--gutter)'
        }}
      >
        <Grid>
          <StyledSubGrid>
            <div style={{ padding: isMobile ? '0' : '80px 0' }}>
              <Heading
                asDescription={'p'}
                asTitle={'h2'}
                description={t('scanSection.description')}
                descriptionTheme={'slate300'}
                icon={scan360Icon}
                label={t('scanSection.label')}
                title={t('scanSection.title')}
                titleTheme={'slate50'}
              />

              <FadeInUp style={{ paddingTop: 'var(--space-xs)' }}>
                <Button
                  aria-label={t('common:actions.checkReport')}
                  colorTheme={'solid'}
                  href={t('scanSection.href')}
                  size={'medium'}
                  target={'_blank'}
                >
                  {t('common:actions.checkReport')}
                </Button>
              </FadeInUp>
            </div>

            <ImageWrapper>
              <div style={{ height: '100%', position: 'relative', width: 'fit-content' }}>
                <a aria-label={t('scanSection.label')} href={t('scanSection.href')} target={'_blank'}>
                  <Image
                    alt={t('scanSection.image.alt')}
                    height={682}
                    src={t('scanSection.image.src')}
                    style={{
                      height: '100%',
                      position: 'relative',
                      width: 'auto'
                    }}
                    width={429}
                  />

                  <StyledBadgePrice>
                    <Text as={'p'} style={{ gridArea: 'price', lineHeight: '100%' }} variant={'h3'} weight={600}>
                      {t('scanSection.badgePrice')}
                    </Text>

                    <Text as={'p'} style={{ gridArea: 'price-symbol', lineHeight: '100%' }} variant={'h4'} weight={600}>
                      {'€'}
                    </Text>

                    <Text as={'p'} style={{ gridArea: 'vat', textAlign: 'center' }} variant={'bodyXxs'}>
                      {t('common:labels.vat')}
                    </Text>
                  </StyledBadgePrice>
                </a>
              </div>
            </ImageWrapper>
          </StyledSubGrid>
        </Grid>

        <IconsGrid ref={iconsGridRef}>
          <IconParallax
            baseVelocity={0.5}
            icon={electricIcon}
            isInView={isInView}
            name={t('icons.electric')}
            style={{
              alignSelf: 'end',
              gridArea: 'icon1',
              justifySelf: 'end'
            }}
          />

          <IconParallax
            icon={engineIcon}
            isInView={isInView}
            name={t('icons.engine')}
            style={{
              gridArea: 'icon2',
              marginTop: '-38px'
            }}
          />

          <IconParallax
            baseVelocity={3}
            icon={carIcon}
            isInView={isInView}
            name={t('icons.bodywork')}
            style={{
              gridArea: 'icon3',
              scale: 1.5
            }}
          />
        </IconsGrid>
      </Section>
    </AnimationController>
  );
};
